import { Injectable } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class LocalisationService {

  constructor(private translate: TranslateService) {
    this.translate.setDefaultLang('en');
    this.translate.onLangChange.subscribe(this.storeLanguage);
  }

  detectLanguage() {
    const browserLanguage = this.translate.getBrowserLang();
    const localLanguage = this.loadLanguage();

    const lang = localLanguage || browserLanguage;
    this.setUserLanguage(lang ?? '');
  }

  setUserLanguage(language: string) {
    this.translate.use(language);
  }

  storeLanguage(event: LangChangeEvent) {
    localStorage.setItem('lang', event.lang);
  }

  loadLanguage(): string {
    return localStorage.getItem(this.translate.currentLang || 'lang') ?? 'en';
  }
}
