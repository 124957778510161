import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SiResultDetailsListModule, SiSearchBarModule, SiEmptyStateModule, SiLoadingSpinnerModule } from '@simpl/element-ng';
import { SiDashboardModule } from '@simpl/element-ng/dashboard';


@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  exports: [
    SiResultDetailsListModule,
    SiSearchBarModule,
    SiEmptyStateModule,
    SiLoadingSpinnerModule,
    SiDashboardModule
  ]
})
export class SiModulesModule { }
