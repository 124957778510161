import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../services/authentication/auth.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

  constructor(
    private authService: AuthService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.authService.logout()
      .then(() => {
        console.log('Successfully logged out');
        this.router.navigate(['/landing']);
      })
      .catch(error => {
        console.error(`Error occured during logout: ${JSON.stringify(error)}`, error);
      });
  }

}
