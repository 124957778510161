import { AuthConfig } from 'angular-oauth2-oidc';


export const authCodeFlowConfig: AuthConfig = {
  issuer: 'https://login.microsoftonline.com/38ae3bcd-9579-4fd4-adda-b42e1495d55a/v2.0',
  redirectUri: `${window.location.origin}${window.location.pathname}`,
  clientId: '71d8332e-83b3-408f-ae2b-c5692dd7f783',
  responseType: 'code',
  scope: 'openid profile email api://714d7ca2-bd44-43a8-84ce-5d3f588a8920/Conversion.Request api://714d7ca2-bd44-43a8-84ce-5d3f588a8920/Conversion.ViewStatus',
  showDebugInformation: true,
  strictDiscoveryDocumentValidation: false
};
// ${window.location.origin}${window.location.pathname}main${window.location.pathname}dashboard${window.location.pathname}home
// 'http://localhost:4200/main/dashboard/home'
