import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AppPreloadingStrategy } from './app-preloading-strategy';
import { LandingComponent } from './pages/landing/landing.component';
import { LogoutComponent } from './shared/components/logout/logout.component';
import { AuthGuard, UnAuthenticatedGuard } from './shared/guards';

/** Top level routing configuration */
const routes: Routes = [
  { path: '', redirectTo: 'landing', pathMatch: 'full' },
  { path: 'landing', canActivate: [UnAuthenticatedGuard], component: LandingComponent },
  { path: 'main', canActivate: [AuthGuard], loadChildren: () => import('./pages/main/main.module').then(m => m.MainModule) },
  { path: 'logout', canActivate: [AuthGuard], component: LogoutComponent },
  { path: '**', redirectTo: 'landing' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true, /* enableTracing: true,*/ preloadingStrategy: AppPreloadingStrategy })],
  exports: [RouterModule],
  providers: [AppPreloadingStrategy]
})
export class AppRoutingModule { }
