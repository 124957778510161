import { Injectable, StaticProvider, TemplateRef } from '@angular/core';
import {
  AlertDialogResult,
  ConfirmationDialogResult,
  DeleteConfirmationDialogResult,
  EditDiscardDialogResult,
  ModalOptions,
  SiActionDialogService,
} from '@simpl/element-ng';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Observable, Subject } from 'rxjs';

import { ModalInjectorService } from './modal-injector.service';
import { CacheGenerationProgress } from '../models/data/browse-progress-data';

@Injectable({
  providedIn: 'root'
})
export class ProjectModalService {

  private _componentName: Subject<any> = new Subject<any>();
  private _modalInjector: Subject<any> = new Subject<any>();
  private _progressVisibility: boolean = false;
  private _cacheGenProgressModel: CacheGenerationProgress;

  public modalTemplate?: TemplateRef<any>;

  constructor(private siModal: SiActionDialogService, private modalService: BsModalService) {

    this._cacheGenProgressModel = {
      Complete: false,
      Progress: 0,
      TotalProjectsCount: 0
    };
   }

  logEvent!: (p: any) => void;

  get cacheGenProgressModel(): CacheGenerationProgress {
    return this._cacheGenProgressModel;
  }
  set cacheGenProgressModel(cacheGenProgressModel : CacheGenerationProgress) {
    this._cacheGenProgressModel = cacheGenProgressModel;
  }

  get progressVisibility(): boolean {
    return this._progressVisibility;
  }
  set progressVisibility(progressVisbility: boolean) {
    this._progressVisibility = progressVisbility;
  }

  get componentName$(): Observable<any> {
    return this._componentName.asObservable();
  }

  get modalInjector$(): Observable<any> {
    return this._modalInjector.asObservable();
  }

  showAlert(alertMessage: string, alertTitle: string, alertButtonName?: string): Observable<AlertDialogResult> {
    return this.siModal.showAlertDialog(alertMessage, alertTitle, alertButtonName);
  }

  showConfirmation(confirmationMessage: string, confirmationTitle: string, confirmButtonTitle?: string, declineButtonTitle?: string): Observable<ConfirmationDialogResult> {
    return this.siModal.showConfirmationDialog(confirmationMessage, confirmationTitle, confirmButtonTitle ?? 'Yes', declineButtonTitle ?? 'Cancel');
  }

  showEditAbort(discardConfirmMessage: string, discardConfirmTitle: string, saveButtonTitle?: string, abortButtonTitle?: string, disableSave: boolean = false): Observable<EditDiscardDialogResult> {
    return this.siModal.showEditDiscardDialog(
      disableSave,
      discardConfirmMessage,
      discardConfirmTitle,
      saveButtonTitle,
      abortButtonTitle
    );
  }

  showDeleteConfirmation(deleteConfirmMessage: string, deleteConfirmTitle: string, deleteButtonName: string): Observable<DeleteConfirmationDialogResult> {
    return this.siModal.showDeleteConfirmationDialog(
      deleteConfirmMessage,
      deleteConfirmTitle,
      deleteButtonName
    );
  }

  closeModal() {
    this.modalService.hide();
  }

  public openModal(componentName: any, params: ModalInjectorService, modalTemplate?: TemplateRef<any>, config?: ModalOptions): void {

    const injectionData: StaticProvider = {
      provide: ModalInjectorService, useClass: ModalInjectorService,
      useValue: params
    };

    this._componentName.next(componentName);
    this._modalInjector.next(injectionData);
    if (this.modalTemplate === undefined || this.modalTemplate === null) {
      console.log(`"modalTemplate" undefined`);
    }
    else {
      this.modalService.show(this.modalTemplate);
    }
  }

}

