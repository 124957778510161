import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/authentication/auth.service';
// import { UrlStoreService } from 'src/app/shared/services/url-store.service';

@Component({
  templateUrl: './landing.component.html'
})
export class LandingComponent implements OnInit {

  environment = localStorage.getItem('ConversionEnv') ? localStorage.getItem('ConversionEnv') : 'Select environment';

  constructor(
    private router: Router,
    private authService: AuthService,
    // private urlStoreService: UrlStoreService
  ) { }

  ngOnInit() {
    if (this.authService.isAuthenticated()) {
      this.redirectIfAuthenticated();
    }
  }

  redirectIfAuthenticated() {
    this.router.navigate(['/main']);
  }

  login() {
    this.authService.tryLogin();
    // this.authService.tryLoginFlow();
    // if (this.authService.isAuthenticated()) {
    //   const abt = this.authService.getUserData();
    //   console.log(abt);
    // }
  }


  // selectDropdownEvent(event: { innerHTML: string; }) {
  //   this.environment = event.innerHTML;
  //   this.urlStoreService.setEnvironment(event.innerHTML);
  // }

}
