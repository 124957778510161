  <!-- <div siDropdown #dropdown="si-dropdown" (dropdownOnSelect)="selectDropdownEvent($event)" class="btn-group me-5">
    <button siDropdownToggle type="button" class="btn btn-primary dropdown-toggle">
      {{environment}} <i class="icon element-down-2"></i>
    </button>
    <ul id="dropdown-triggers-manual" role="menu"
        class="dropdown-menu" siDropdownMenu
        aria-labelledby="button-triggers-manual">
      <li role="presentation" class="dropdown-header">Available environment</li>
      <li role="menuitem"><span class="dropdown-item">Development</span></li>
      <li role="menuitem"><span class="dropdown-item">Testing</span></li>
    </ul>
  </div> -->

  <si-landing-page
  [heading]="'APP.NAME' | translate"
  [subtitle]="'APP.CLAIM' | translate"
  [links]="[
    {title: 'Corporate information', href: 'http://www.siemens.com/corporate-information'},
    {title: 'Privacy policy', href: 'http://www.siemens.com/privacy-notice'},
    {title: 'Terms of use', href: 'http://www.siemens.com/terms-of-use'}
  ]"
  [availableLanguages]="['en', 'de', 'fr']"
>
  <button type="submit" class="btn btn-primary" style="width: 100%;" (click)="login()">
    <!-- routerLink="/main"> -->
    {{ 'APP.LOGIN' | translate }}
  </button>
</si-landing-page>
