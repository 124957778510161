import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { SiDropdownModule, SiIconModule, SiModalService, SiNavbarModule, SiPaginationModule } from '@simpl/element-ng';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { BsModalService } from 'ngx-bootstrap/modal';
import { HighlightModule } from 'ngx-highlightjs';

import { CheckinProjectsModalComponent } from './components/checkin-projects-modal/checkin-projects-modal.component';
import {
  ConversionProjectDetailedLogsComponent,
} from './components/conversion-project-detailed-logs/conversion-project-detailed-logs.component';
import { ConversionProjectStageComponent } from './components/conversion-project-stage/conversion-project-stage.component';
import { ConversionProjectsListComponent } from './components/conversion-projects-list/conversion-projects-list.component';
import { DownloadProjectsModalComponent } from './components/download-projects-modal/download-projects-modal.component';
import { LogoutComponent } from './components/logout/logout.component';
import { ProjectConversionModalComponent } from './components/project-conversion-modal/project-conversion-modal.component';
import { RetryConversionModalComponent } from './components/retry-conversion-modal/retry-conversion-modal.component';
import { UndoCheckoutModalComponent } from './components/undo-checkout-modal/undo-checkout-modal.component';
import { DynamicPaginationComponent } from './dynamic-pagination/dynamic-pagination.component';
import { ModalInjectorService } from './services/modal-injector.service';
import { ProjectModalService } from './services/project-modal.service';
import { SiModulesModule } from './si-modules/si-modules.module';

// import { ModalMessageComponent } from './components/modal-message/modal-message.component';
// import { ProjectModalDirective } from './directive/project-modal.directive';
@NgModule({
  imports: [
    TranslateModule,
    CommonModule,
    SiModulesModule,
    BsDropdownModule.forRoot(),
    HighlightModule,
    SiDropdownModule,
    SiPaginationModule,
    SiNavbarModule,
    SiIconModule
  ],
  exports: [
    TranslateModule,
    ConversionProjectsListComponent,
    ConversionProjectStageComponent,
    ConversionProjectDetailedLogsComponent,
    DynamicPaginationComponent,
    SiNavbarModule,
    SiIconModule
  ],
  declarations: [
    ConversionProjectsListComponent,
    ConversionProjectStageComponent,
    ConversionProjectDetailedLogsComponent,
    // ModalMessageComponent,
    // ProjectModalDirective,
    ProjectConversionModalComponent,
    DownloadProjectsModalComponent,
    CheckinProjectsModalComponent,
    UndoCheckoutModalComponent,
    RetryConversionModalComponent,
    DynamicPaginationComponent,
    LogoutComponent
  ],
  providers: [
    // {
    //   provide: HIGHLIGHT_OPTIONS,
    //   // TODO: See later
    //   // useValue: {
    //   //   fullLibraryLoader: () => import('highlight.js'),
    //   //   lineNumbersLoader: () => import('highlightjs-line-numbers.js')
    //   // } as HighlightOptions
    // },
    ProjectModalService,
    SiModalService,
    BsModalService,
    ModalInjectorService
  ]
})
export class SharedModule { }
