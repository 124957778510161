import { HttpClient, HttpClientModule } from '@angular/common/http';
import { ApplicationRef, DoBootstrap, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SiDropdownModule, SiLandingPageModule, SiProgressbarModule, SiNavbarModule, SiNavbarSecondaryModule } from '@simpl/element-ng';
import { OAuthModule } from 'angular-oauth2-oidc';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LandingComponent } from './pages/landing/landing.component';
import { SharedModule } from './shared';
import { AuthService } from './shared/services/authentication/auth.service';

// import { ModalMessageComponent } from './shared/components/modal-message/modal-message.component';


export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    LandingComponent
  ],
  imports: [
    BrowserAnimationsModule,
    AppRoutingModule,
    SiLandingPageModule,
    SharedModule,
    HttpClientModule,
    SiDropdownModule,
    SiProgressbarModule,
    SiNavbarModule,
    SiNavbarSecondaryModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    OAuthModule.forRoot()
  ]
  // providers: [
  // ],
  // exports: [RouterModule],
  // bootstrap: [AppComponent]
})
export class AppModule implements DoBootstrap {

  constructor(private authService: AuthService) { }

  ngDoBootstrap(appRef: ApplicationRef): void {
    this.authService.bootstrapAuthService()
      .then(() => {
        appRef.bootstrap(AppComponent);
      })
      .catch(error => {
        console.error(`[ngDoBootstrap] Problem while authService.bootstrapAuthService(): ${JSON.stringify(error)}`, error);
      });
  }

}
